@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap);
@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.ef2f4ee9.eot);
  src: url(/static/media/icomoon.ef2f4ee9.eot#iefix) format("embedded-opentype"),
    url(/static/media/icomoon.bc8ea55a.ttf) format("truetype"),
    url(/static/media/icomoon.d310492c.woff) format("woff"),
    url(/static/media/icomoon.8d11e1b8.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.mltColorIcon {
  font-size: 20px;
}

.icon-sending-mail .path1:before {
  content: "\e926";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-sending-mail .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-sending-mail-blue .path1:before {
  content: "\e928";
  color: rgb(63, 115, 246);
  opacity: 0.3;
}
.icon-sending-mail-blue .path2:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(63, 115, 246);
}
.icon-sending-mail-gray .path1:before {
  content: "\e92a";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-sending-mail-gray .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(153, 161, 170);
}
.icon-lock:before {
  content: "\e912";
}
.icon-user .path1:before {
  content: "\e939";
  color: rgb(41, 41, 48);
  opacity: 0.3;
}
.icon-user .path2:before {
  content: "\e93a";
  margin-left: -0.9599609375em;
  color: rgb(41, 41, 48);
}
.icon-user-blue .path1:before {
  content: "\e93b";
  color: rgb(63, 115, 246);
  opacity: 0.3;
}
.icon-user-blue .path2:before {
  content: "\e93c";
  margin-left: -0.9599609375em;
  color: rgb(63, 115, 246);
}
.icon-user-square:before {
  content: "\e93d";
}
.icon-message .path1:before {
  content: "\e917";
  color: rgb(39, 39, 46);
  opacity: 0.3;
}
.icon-message .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(39, 39, 46);
}
.icon-message-blue .path1:before {
  content: "\e919";
  color: rgb(63, 115, 246);
  opacity: 0.3;
}
.icon-message-blue .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(63, 115, 246);
}
.icon-tick:before {
  content: "\e932";
}
.icon-tick-circle:before {
  content: "\e933";
}

.icon-cross:before {
  content: "\e90b";
}

@font-face {
  font-family: "inter400";
  src: url(/static/media/Inter-Regular.a3e5baa2.ttf);
  src: url(/static/media/Inter-Regular.a3e5baa2.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "inter500";
  src: url(/static/media/Inter-Medium.4ebf1acf.ttf);
  src: url(/static/media/Inter-Medium.4ebf1acf.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "inter700";
  src: url(/static/media/Inter-Bold.1eca2d32.ttf);
  src: url(/static/media/Inter-Bold.1eca2d32.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "poppins400";
  src: url(/static/media/Poppins-Regular.35d26b78.ttf);
  src: url(/static/media/Poppins-Regular.35d26b78.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "poppins500";
  src: url(/static/media/Poppins-Medium.673ed423.ttf);
  src: url(/static/media/Poppins-Medium.673ed423.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "poppins600";
  src: url(/static/media/Poppins-SemiBold.ac8d04b6.ttf);
  src: url(/static/media/Poppins-SemiBold.ac8d04b6.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "poppins700";
  src: url(/static/media/Poppins-Bold.cdb29a5d.ttf);
  src: url(/static/media/Poppins-Bold.cdb29a5d.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto400";
  src: url(/static/media/Roboto-Regular.fc2b5060.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SFProDisplay-Regular.7ee14c63.woff) format("woff"),
    url(/static/media/SFProDisplay-Regular.d5ff9f68.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SFProDisplay-Semibold.340c3a1a.woff) format("woff"),
    url(/static/media/SFProDisplay-Semibold.3926eb65.woff2) format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SFProDisplay-Bold.5b08afd6.woff) format("woff"),
    url(/static/media/SFProDisplay-Bold.84c242fe.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

