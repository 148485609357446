@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFPro/SFProDisplay-Regular.woff") format("woff"),
    url("./assets/fonts/SFPro/SFProDisplay-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFPro/SFProDisplay-Semibold.woff") format("woff"),
    url("./assets/fonts/SFPro/SFProDisplay-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFPro/SFProDisplay-Bold.woff") format("woff"),
    url("./assets/fonts/SFPro/SFProDisplay-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
